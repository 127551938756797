import React, { useContext, useEffect, useState } from "react";
import Contract from "../contracts/CartoonKevins.json";
import UserContext  from '../context/user';
import UiContext  from '../context/ui';
import {CONTRACT_ADDRESS} from './Helper'

function MintButton () {

    const { connectedWalletAddress, web3Context } = useContext(UserContext);
    const { displayPopupWithCallback, displayLoaderWithCallback, loaderFinished } = useContext(UiContext);
    const [totalSupply, setTotalSupply] = useState(0);
    const [maxSupply, setMaxSupply] = useState(0);
    const [saleState, setSaleState] = useState(0);
    const [contractState, setContractState] = useState(null);
    const [numberToMint, setNumberToMint] = useState(1);
    const [isSoldOut, setIsSoldOut] = useState(false);

    useEffect( ()=>{

        const fetchWeb3 = async ()=> {
            if (web3Context) {
                await web3Context.eth.net.getId();    
                let contract_Address = CONTRACT_ADDRESS;
                if (contract_Address) {
                    
                    try {
                    
                        const contractResult = new web3Context.eth.Contract(
                            Contract, contract_Address
                        );
                        setContractState(contractResult)
                        await queryContract(contractResult);
                    }catch (e) {
                        console.log("Error")

                        displayPopupWithCallback(()=>{  
                        },
                        "Error Connecting to the Ethereum Network",
                        "Please connect to Mainnet", true);                    
                    }
                    


                } else {
                    displayPopupWithCallback(()=>{  
                    },
                    "Error Connecting to the Ethereum Network",
                    "Please connect to Mainnet", true);                    
                }
            }
        }

        fetchWeb3();
       
    },[web3Context]);

    const [animateBulge, setAnimateBulge] = useState(false);

    useEffect(() => {
        // Trigger bulge animation
        setAnimateBulge(true);

        const timer = setTimeout(() => {
            setAnimateBulge(false); // Reset animation state after it finishes
        }, 300); // Match the duration of the bulge animation

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, [numberToMint]); // Run effect when numberToMint changes

    const queryContract = async (contractResult)=> {

        let totalSupplyResult = await contractResult.methods.totalSupply().call();
       const totalSupplyAsNumber = Number(totalSupplyResult); // Convert to Number if safe
        setTotalSupply(totalSupplyAsNumber);        
        let saleStateResult = await contractResult.methods.paused().call();
        //Checks if paused
        setSaleState(saleStateResult);    
        let maxSupplyResult = await contractResult.methods.maxSupply().call();
        const maxSupplyResultAsNumber = Number(maxSupplyResult); // Convert to Number if safe
        setMaxSupply(maxSupplyResultAsNumber);    
        if (totalSupplyResult >= maxSupplyResult) {
            setIsSoldOut(true);
        }

    }
    const handleMouseDown = (elementId) => {
        let docElement = document.getElementById(elementId);
        if (docElement) {
            docElement.style.transform = 'scale(0.96)';
            docElement.style.transition = 'transform 0.05s ease-in-out';
        }
    };
    
    const resetTransform = (elementId) => {
        let docElement = document.getElementById(elementId);
        if (docElement) {
            docElement.style.transform = 'scale(1)';
        }
    };

    function gweiToEth(gwei) {
        // return gwei / 1e9; // Divides by 1 billion

        let ethBalance = web3Context.utils.fromWei(gwei, 'ether');
        return ethBalance;
    }

    
    const sendMintTransaction = async (contract) => {
        try {
            // Get the price of the minting
            let getPrice = await contract.methods.cost().call();
            // Calculate the total cost
            const totalCost = Number(getPrice) * Number(numberToMint); // Total cost for minting
            let estimatedGas = 300000;
            
            try {
            estimatedGas = await contract.methods.mint(numberToMint).estimateGas({ 
                from: connectedWalletAddress, 
                value: totalCost.toString()
            });
            }catch (e) {
                // throw new Error("Insufficient funds for gas * price + value");                
                displayPopupWithCallback(() => {
                    console.log("randomize pressed");
                },
                "Insufficient funds!",
                `You'll need atleast ${totalCost}`, true);                
            }
            
            // Get the current gas price
            // const gasPrice = await web3Context.eth.getGasPrice();
            // console.log("Current Gas Price:", gasPrice);    
            // Calculate total gas cost
            const totalGasCost =  Number(estimatedGas);    
            // Calculate total amount required for the transaction
            const totalAmountRequired = totalCost + totalGasCost;
            // Check if the account has sufficient funds
            const accountBalance = await web3Context.eth.getBalance(connectedWalletAddress);
            if (Number(accountBalance) < totalAmountRequired) {
                // throw new Error("Insufficient funds for gas * price + value");                
                displayPopupWithCallback(() => {
                    console.log("randomize pressed");
                },
                "Insufficient funds!",
                `You'll need atleast ${gweiToEth(totalAmountRequired)} eth`, true);
            }
    
            // Send the minting transaction
            await contract.methods.mint(numberToMint).send({ 
                from: connectedWalletAddress, 
                value: totalAmountRequired.toString(),  // Convert to string for Web3
                gas: estimatedGas // Include the estimated gas
            });
    
            loaderFinished();
            displayPopupWithCallback(() => {
                console.log("randomize pressed");
            },
            "Mint Complete!",
            "You successfully minted Cartoon Kevin!", true);
        } catch (error) {
            console.error("Error sending mint transaction:", error);
            loaderFinished();
            displayPopupWithCallback(() => { }, "Error", error.message, true);
        }
    };
    

    
    const displayTotalSupply = ()=>{        
        return (<div className="total-supply">{totalSupply}/{maxSupply}</div>)
    }

    return(<>
                             
            {
                isSoldOut ? <>
                 <div className="mint-button noselect" onClick={async ()=>{ 
                 }}>Sold Out</div>
                </> : <>
                <div className="mint-number">
                <div id="mint-button-minus" className="mint-button toggle noselect" 
                
                onMouseDown={() => handleMouseDown("mint-button-minus")}
                onMouseUp={() => resetTransform("mint-button-minus")}
                onMouseLeave={() => resetTransform("mint-button-minus")}
                onTouchStart={() => handleMouseDown("mint-button-minus")}
                onTouchEnd={() => resetTransform("mint-button-minus")}
    
                onClick={async ()=>{ 
                    setNumberToMint(Math.max(1, numberToMint-1));
                }}>-</div>
                
                

                <div className={`mint-button-number ${animateBulge ? 'bulge' : ''}`}>
                            {numberToMint}
                        </div>                
        
            <div id="mint-button-plus" className="mint-button toggle noselect" 
            
            onMouseDown={() => handleMouseDown("mint-button-plus")}
            onMouseUp={() => resetTransform("mint-button-plus")}
            onMouseLeave={() => resetTransform("mint-button-plus")}
            onTouchStart={() => handleMouseDown("mint-button-plus")}
            onTouchEnd={() => resetTransform("mint-button-plus")}
    
            
            onClick={async ()=>{ 
                    setNumberToMint(Math.min(5, numberToMint+1));
                }}>+</div>                 
                </div>
            
                 <div id="mint-button" className="mint-button" 
                 
                 onMouseDown={() => handleMouseDown("mint-button")}
                 onMouseUp={() => resetTransform("mint-button")}
                 onMouseLeave={() => resetTransform("mint-button")}
                 onTouchStart={() => handleMouseDown("mint-button")}
                 onTouchEnd={() => resetTransform("mint-button")}
     
                 onClick={async ()=>{ 

                if (saleState) {
                    displayPopupWithCallback(()=>{
                    },
                    "Error Minting",
                    "Sales are paused", true);         
                    return;
                }
                
                displayLoaderWithCallback(async ()=>{
                    console.log("Finished loader")
                })                                
    
                if (web3Context === null) {
                    loaderFinished();
                    displayPopupWithCallback(()=>{
                        console.log("randomize pressed");
                    },
                    "Error Minting",
                    "Please connect your wallet.  Tip: Refresh the page after logging in to your wallet.", true);                    

                    return;
                 }
            
            try {
                displayPopupWithCallback(()=>{
                    console.log("randomize pressed");
                },
                "Interacting with your wallet",
                "Please check your wallet to verify the transaction.", true);                    

                if (connectedWalletAddress && contractState) {
                    try {

                        displayPopupWithCallback(()=>{
                            console.log("randomize pressed");
                        },
                        "Interacting with your wallet",
                        "Please check your wallet to verify the transaction.", true);                    

                        await sendMintTransaction(contractState);
                    }catch (e) {
                        console.log("Error minting")
                    }



                } else {
                    displayPopupWithCallback(()=>{  
                    },
                    "Error Minting",
                    "Please connect a wallet to mint", true);                    
                }
            } catch (e) {
                if (e.message) {
                    loaderFinished();                                
                    displayPopupWithCallback(()=>{
                        console.log("randomize pressed");
                    },
                    "Error",
                    e.message, true);                    
                    }
                }
            }
        }>
          Mint
        </div>
                </>
        }            
        {
            saleState  === true ? <>mint paused <br/></> :<></>
        }
        {
            // saleState  == 1 ? <p>presale mint</p> :<></>
        }

        {
            displayTotalSupply()
        }
    </>)
}

export default MintButton;