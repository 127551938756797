import React, {useEffect, useContext} from "react";
import UserContext  from '../context/user';
import UiContext  from '../context/ui';
import {ETHERSCAN_CONTRACT_ADDRESS, OPENSEA_ADDRESS} from './Helper'

function MenuBar (props) {

    const { isConnected, connectedWalletAddress, disconnectWallet, connectWallet } = useContext(UserContext);
    const { alertMessage } = useContext(UiContext);
    // const router = useRouter()
    const displayConnected = ()=>{
        return <div className="connect-wallet-button" onClick={()=>{          
          // router.push("/manage")

          // disconnectWallet();

      }}> <div className="connect-wallet-button-connected">{"Connected " + connectedWalletAddress}</div>
        <div style={{display:"inline-block"}} >
       {
        // isConnected ? <img className="web-avatar-profile" src="/images/kevins/1.png"></img> : <></>
       }
      </div>
     </div>;
    }
  
    useEffect(() =>{
      // console.log("alertMessage:", alertMessage);
    }, [alertMessage])
      
    return (<div className="top-bar mobile-hide top-bar-rainbow-corner">                                                  
    <img alt="" onClick={()=>{
        // navigate('/', { replace: true })
        // router.push("/")
    }}className="top-bar-logo" src="/images/Cartoon Kevins.png"/>
    <div className="top-bar-right">
      
      <div className="top-bar-menu-item">
      <a target="_blank" rel="noreferrer" href={OPENSEA_ADDRESS}>
      <img alt="" className="top-bar-icon" src="images/opensea.png"/>            
      </a>
      </div>

      <div className="top-bar-menu-item">
      <a target="_blank" rel="noreferrer" href={ETHERSCAN_CONTRACT_ADDRESS}>
      <img alt="" className="top-bar-icon" src="images/logo-etherscan-white.svg"/>            
      </a>
      </div>

      <div className="top-bar-menu-item">
      <a target="_blank" rel="noreferrer" href="https://twitter.com/cartoonkevins">
      <img alt="" className="top-bar-icon" src="images/twitter.png"/>            
      </a>
      </div>
      
      <div className="top-bar-menu-item">
      <a target="_blank" rel="noreferrer" href="https://instagram.com/cartoonkevins">
      <img alt="" className="top-bar-icon" src="images/instagram.png"/>            
      </a>
      </div>
      
      {
       isConnected ? displayConnected() :  <div className="connect-wallet-button" onClick={ async ()=>{
        // if (!window.ethereum) {
        //   window.alert('Please install MetaMask first.');
        //   return;
        // }    
        await connectWallet();
      }}>
       <div style={{verticalAlign:"top", paddingTop:"6px", display:"inline-block"}}> Connect Wallet </div>       
      </div>
     }   
    </div>             
    {
                alertMessage ? <>
                <div>
                  <p>{alertMessage}</p>
                </div>
                </>: <></>
            }              
  </div>
)
}


export default MenuBar;