import './App.css';
import HomePage from './components/HomePage';
import Popup from './components/Popup';
// import Loader from './components/Loader';
// import Game from './components/Game';
// import Menu from './components/Menu';
// import LoadingScreen from './components/LoadingScreen';

function App() {
  return (    
    <div className="App">      
      <Popup/> 
      <HomePage />      
      {/* 
      
      <Loader/> 
      <Game/> 
      <Menu />      
      <LoadingScreen /> */}
      
    </div>
  );
}

export default App;
