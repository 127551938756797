import React, { useState, useEffect, useContext } from "react";


const EngineContext = React.createContext({});

const EngineProvider = ({ children }) => {

    const [gameScene, setGameScene] = useState(null);
    const [shadowGenerator, setShadowGenerator] = useState(null);

  useEffect(()=>{
       
  },[]);


const updateGameScene = (updatedScene) => {
    setGameScene(updatedScene);
}

const updateShadowGenerator = (sG) => {
    setShadowGenerator(sG)
}

const addToShadowGenerator = (mesh) => {
    if (shadowGenerator && mesh) {
        console.log("Added to shadow map list");
        shadowGenerator.getShadowMap().renderList.push(mesh);        
    }
}

return (
    <EngineContext.Provider
      value={{shadowGenerator, updateShadowGenerator, updateGameScene,  addToShadowGenerator}}>
      {children}
    </EngineContext.Provider>
  );
};

export default EngineContext;
export {EngineProvider};