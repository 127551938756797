import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {UiProvider} from './context/ui';
import {UserProvider} from './context/user';
import {EngineProvider} from './context/engine';
import { BrowserRouter } from "react-router-dom"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <UiProvider><EngineProvider><UserProvider><App /></UserProvider></EngineProvider></UiProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);