import React, { useEffect, useContext } from "react";
import UiContext from '../context/ui';

function Popup({ data }) {
    const { popupMessage, popupOkPressed, popupCancelPressed } = useContext(UiContext);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                popupCancelPressed();
                console.log("Cancel pressed");
            }
        };

        // Add event listener
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [popupCancelPressed]);

    const isVisible = () => {
        return popupMessage.visibility === "hidden" ? "hidden" : "visible";
    };

    const handleMouseDown = (elementId) => {
        let docElement = document.getElementById(elementId);
        if (docElement) {
            docElement.style.transform = 'scale(0.96)';
            docElement.style.transition = 'transform 0.05s ease-in-out';
        }
    };

    const resetTransform = (elementId) => {
        let docElement = document.getElementById(elementId);
        if (docElement) {
            docElement.style.transform = 'scale(1)';
        }
    };

    const handleOkPress = () => {
        popupOkPressed();        
    };

    return (
        <div className="popup-screen-group">
            <div className={`popup-screen ${isVisible()}`}>
                <div className={`popup-box ${isVisible()}`}>
                    <div>
                        <p className="popup-title">{popupMessage ? popupMessage.title : ""}</p>
                        
                        {
                            popupMessage?.title === "Mint Complete!" ?  <img style={{width:"100%"}} src="/images/Hidden-animated.gif" /> : <></>                            
                        }

                        <p className="popup-message">{popupMessage ? popupMessage.message : ""}</p>
                    </div>
                    <div>
                        <div 
                            id="ok-button" 
                            className="popup-button .noselect" 
                            onMouseDown={() => handleMouseDown("ok-button")}
                            onMouseUp={() => resetTransform("ok-button")}
                            onMouseLeave={() => resetTransform("ok-button")}
                            onTouchStart={() => handleMouseDown("ok-button")}
                            onTouchEnd={() => resetTransform("ok-button")}
                            onClick={handleOkPress}
                        >
                            OK
                        </div>
                        {
                            popupMessage && popupMessage.okOnly ? null : (
                                <div className="popup-button .noselect" onClick={() => {
                                    popupCancelPressed();
                                    console.log("Cancel pressed");
                                }}>
                                    Cancel
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Popup;