import React, { useEffect, useState, useContext, useRef } from "react";
import MenuBar from "./MenuBar";
import MintButton from "./MintButton";
import UserContext  from '../context/user';
import bg from './bg.jpg';
import {ETHERSCAN_CONTRACT_ADDRESS, OPENSEA_ADDRESS} from './Helper';

function HomePage() {

  const { isConnected, connectToWeb3 } = useContext(UserContext);
  const currentAvatarsShown = useRef([]);
  const [updateAvatarsShown, setUpdateAvatarsShown] = useState(false);

  const getAvatars = () => {

    let avatarsList = ["/images/kevins/1.jpg",
        "/images/kevins/2.jpg",
        "/images/kevins/3.jpg",
        "/images/kevins/4.jpg",
        "/images/kevins/5.jpg",
        "/images/kevins/6.jpg",
        "/images/kevins/7.jpg",
        "/images/kevins/9.jpg",
        "/images/kevins/10.jpg",
        "/images/kevins/11.jpg",
        "/images/kevins/12.jpg",
        "/images/kevins/13.jpg",
        "/images/kevins/14.jpg",
        "/images/kevins/15.jpg"
        ];

  if (currentAvatarsShown.current.length === 0) {
    
    let updatedList = avatarsList.slice(0,3);
    currentAvatarsShown.current = updatedList;
  } else {

    let current = [...currentAvatarsShown.current];
    const reRollRandom = (a)=>{
      let randomIndex = Math.floor(Math.random()*a.length);
      return randomIndex;
    }        
    let avatarIndexRolled = reRollRandom(avatarsList);
    let avatarFound = false;
    while (!avatarFound) {        
      let dupeFound = false;          
      for (let i = 0; i < current.length; i++) {
        let currentStringCheck = current[i];
        if (currentStringCheck === avatarsList[avatarIndexRolled]) {
          dupeFound = true;
        }
      }
      if (dupeFound) {
        avatarIndexRolled = reRollRandom(avatarsList);
      }else {
        avatarFound = true;
      }
    }        
    
    current[reRollRandom(current)] = avatarsList[avatarIndexRolled];    

    currentAvatarsShown.current = [...current];

    setUpdateAvatarsShown(!updateAvatarsShown);
  }
}

  useEffect(() => {
    getAvatars();
    getAvatars();
  }, []);
  
  // const displayAvatarsRow = ()=> {
  //   return (currentAvatarsShown.current.map((avatarLink, index) =>{
  //     return  <img alt="" key={index} className="web-avatar-square" src={avatarLink}></img> 
  //   }))
  // }

  const handleMouseDown = (elementId) => {
    let docElement = document.getElementById(elementId);
    if (docElement) {
        docElement.style.transform = 'scale(0.96)';
        docElement.style.transition = 'transform 0.05s ease-in-out';
    }
};

const resetTransform = (elementId) => {
    let docElement = document.getElementById(elementId);
    if (docElement) {
        docElement.style.transform = 'scale(1)';
    }
};

  const displayAvatarsRow = () => {
    return currentAvatarsShown.current.map((avatarLink, index) => {
      return (
        <img
          alt=""
          key={index}
          className="web-avatar-square"
          src={avatarLink}
          style={{ animationDelay: `${index * 0.15}s` }} // Adjust delay as needed
        />
      );
    });
  };
  const footer = ()=>{
      return(<div className="footer">

<img alt="" className="top-bar-logo" src="/images/Cartoon Kevins.png"/>
        
      <div className="top-bar-menu-item">
      <a target="_blank" rel="noreferrer" href={OPENSEA_ADDRESS}>
      <img alt="" className="top-bar-icon" src="images/opensea.png"/>            
      </a>
      </div>
      <div className="top-bar-menu-item">
      <a target="_blank" rel="noreferrer" href={ETHERSCAN_CONTRACT_ADDRESS}>
      <img alt="" className="top-bar-icon" src="images/logo-etherscan-white.svg"/>            
      </a>
      </div>
      <div className="top-bar-menu-item">
      <a target="_blank" rel="noreferrer" href="https://twitter.com/cartoonkevins">
      <img alt="" className="top-bar-icon" src="images/twitter.png"/>            
      </a>
      </div>
      <div className="top-bar-menu-item">
      <a target="_blank" rel="noreferrer" href="https://instagram.com/cartoonkevins">
      <img alt="" className="top-bar-icon" src="images/instagram.png"/>            
      </a>
      </div>
      </div>)
  }
  return (<div className="App-Contents">
    <div>
      <div className="top-bar desktop-hide">                   
        <img alt="" className="top-bar-logo" src="/images/Cartoon Kevins.png"/>
      </div>
      
      <div className="bg" style={{backgroundImage:`url(${bg})`}} >

      <MenuBar />
            
     <div className="web-block">                  
        <div className="web-block-center">
      

        <div className="avatar-scroll">
        {
          displayAvatarsRow()
        }
      </div>

        <div className="web-block-1-3">
        <p style={{marginTop:"70px"}}><b>3,333</b> Cartoon Kevins</p>
        <p><b>3</b> Legendary Kevins</p>
        <p><b>33</b> Robot & <b>33</b> Rainbow Kevins</p>
        </div>
        </div>
      </div>
    </div>

     <div className="web-block">          
      <div className="web-block-mint">        
       {isConnected ?  <MintButton /> :  <div id="mint-button" className="mint-button" 
       
       onMouseDown={() => handleMouseDown("mint-button")}
       onMouseUp={() => resetTransform("mint-button")}
       onMouseLeave={() => resetTransform("mint-button")}
       onTouchStart={() => handleMouseDown("mint-button")}
       onTouchEnd={() => resetTransform("mint-button")}

       onClick={()=>{connectToWeb3();}}>Connect</div> }
      </div>
    </div>

    {/* 6250000000000 */}
    <div className="web-block ourtool">          
         <div className="web-block-center">Connect your wallet to the Ethereum Mainnet to view mint status. <br/>1 Cartoon Kevin is 0.00625 ether to mint + gas fees.<br/>Max of 5 mints per transaction.</div>
     </div>

  <br/>
    <div className="web-block avatars">          
      <div className="web-block-center-avatars">
        <br/>
        <p className="web-block-text-large dark">Get your own Cartoon Kevin!</p>
        <img alt="" className="web-avatar" src="/images/Kevin-transparent-small.png"></img>
    </div>        
    </div>

        <div className="web-block who">          
            <div className="web-block-center max-width">
                <p className="web-block-text-large dark"><b>Who is Cartoon Kevin?</b></p>
                <p>A legend was born out of chaos. Kevin represents a hero that kept his composure and arose when time needed him most.</p>
                <p>No roadmap, No discord, only Kevin vibes for now.</p>
                <p>Kevin is a resiliant symbol for some in the NFT community, representing true chillness to overcome any obstacle.</p>
                <p>Everyone can be a Kevin.</p>
            </div>

            <div className="web-block-center max-width">
                <p className="web-block-text-large dark"><b>What am I minting?</b></p>
                <p>By minting a Cartoon Kevin you're supporting a builder in the NFT space to build more for Cartoon Kevin holders.</p>                
            </div>

            <div className="web-block-center max-width">
                <p className="web-block-text-large dark"><b>When is reveal?</b></p>
                <p>Reveal will be when the collection sells out to avoid rare sniping. Sign up to get notified via email.</p>
            </div>

            <div className="web-block-center max-width">
                <p className="web-block-text-large dark"><b>Team</b></p>
                
                <img alt="unicornmoneyeth" src="images/unicornmoney.png"></img>
    
                <div className="top-bar-menu-item">
                <a target="_blank" rel="noreferrer" href="https://twitter.com/unicornmoneyeth">
                  <img alt="" className="top-bar-icon" src="images/twitter.png"/>      
                </a>
                <p>unicornmoney</p>      
                </div>
                <p>Founder/CEO, Web3 Developer, Game Developer, NFT Collector, Architect of Things</p>
                <p>Past projects include: Steven Universe: Save the Light, Teeny Titans (Mobile), and more.</p>
            </div>
            <div className="signup-form">
            <iframe src="https://cdn.forms-content-1.sg-form.com/96d62f19-bb30-11ef-8dcd-a2201d78fe1f"/>
            </div>
        </div>        

         
            {
            footer()
            }
        <div>
      </div>
    </div>
  </div>)
  
}

export default HomePage;