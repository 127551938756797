import React, { useState, useEffect, useRef } from "react";

const UiContext = React.createContext({});

const UiProvider = ({ children }) => {  
  
  const [isBusy, setIsBusy] = useState(false);  
  const [alertMessage, setAlertMessage] = useState("");  
  const [uiEvent, setUiEvent] = useState(null);  


  const alertMessageQueue = useRef([]);
  const [popupMessage, setPopupMessage] = useState({
    visibility:"hidden",
    message:"",
    title:"",
    callbackFunc:()=>{},
    okOnly:false        
  })

  const [loaderMessage, setLoaderMessage] = useState({
    visibility:"hidden",
    message:"",
    title:"",
    callbackFunc:()=>{}        
  })



  useEffect(() => {

  }, []);

  const contiueAlertMessageQueueCheck = async ()=> {
    if (alertMessageQueue && alertMessageQueue.current.length > 0) {      
      let message = alertMessageQueue.current[0];
     if (message) {
        setAlertMessage(message);      
     } else {
        setAlertMessage("");
     }       
    } else {
      setAlertMessage("");
    }
  }

  const sendAlertMessage = async (message) => {
    try {     
        alertMessageQueue.current = [];
        alertMessageQueue.current.push(message);
        contiueAlertMessageQueueCheck();
    } catch (err) {      
      // console.log(err);   
    }
  };

  const closeAlertMessage = async ()=> {
    alertMessageQueue.current.shift();
    contiueAlertMessageQueueCheck();
  }

  const clearMessages = async ()=> {
    alertMessageQueue.current = [];
    contiueAlertMessageQueueCheck();
  }

  const totalMessages = async ()=> {
      return alertMessageQueue.current.length;
  }

  const setBusy = (busy)=> {
    setIsBusy(busy);
  }

  const clearPopupData = ()=>{
    setPopupMessage({
      visibility:"hidden",
      message:"",
      callbackFunc:()=>{},
      okOnly:false
    });
  }

  const popupOkPressed = ()=>{
    if (popupMessage.callbackFunc) {
      popupMessage.callbackFunc();
    }
    clearPopupData();
  }

  const popupCancelPressed = ()=>{
    clearPopupData();
  }

  const displayPopupWithCallback = (callbackfunc, title, message, okOnly)=>{

    setPopupMessage({
      visibility:"",
      message:message,
      title:title,
      callbackFunc:callbackfunc,
      okOnly:okOnly
    })
  }

  const clearLoadingData = ()=>{
    setLoaderMessage({
      visibility:"hidden",
      message:"",
      callbackFunc:()=>{}
    });
  }

  const displayLoaderWithCallback = (callbackfunc, title, message)=>{

    setLoaderMessage({
      visibility:"",
      message:message,
      title:title,
      callbackFunc:callbackfunc
    })
  }

  const loaderFinished = ()=>{
    clearLoadingData();
  }

const sendUiEvent = (eventName)=> {
    setUiEvent(eventName);
}

const clearUiEvent = ()=> {
  setUiEvent(null);
}
  
const uiEventUsed = ()=> {
  setUiEvent(null);
}


return (
    <UiContext.Provider
      value={{sendAlertMessage, uiEvent, uiEventUsed, sendUiEvent, clearUiEvent, closeAlertMessage, clearMessages, totalMessages, alertMessage, isBusy, setBusy, loaderFinished,loaderMessage, displayLoaderWithCallback, displayPopupWithCallback, popupMessage, popupOkPressed, popupCancelPressed}}>
      {children}
    </UiContext.Provider>
  );
};

export default UiContext;
export {UiProvider};