
import { Web3 } from 'web3';

export const detectWeb3 = ()=>{

  try {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);           
      return web3.eth.getAccounts(accounts => console.log(accounts));
    } else {
      //Default to local test enviornment
      const provider = new Web3.providers.HttpProvider("http://127.0.0.1:7545");
      const web3 = new Web3(provider);
      return web3.eth.getAccounts(accounts => console.log(accounts));          
    }
  }
  catch(e){
    console.log("Error getting web3:", e);
    return null;
  }
}

const getWeb3 = () => {
  try {
    return new Promise( async (resolve, reject) => {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        try {
          // Request account access if needed
          await window.ethereum.enable();
          resolve(web3);
        } catch (error) {

          console.log("SOME ERROR:", error);

          reject(error);
        }
      } else {
        const provider = new Web3.providers.HttpProvider(
          "http://127.0.0.1:7545"
        );
        const web3 = new Web3(provider);
        console.log("No web3 instance injected, using Local web3.");
        resolve(web3);
      }  
    });
  } catch (e) {
    console.log("Error getting web3:", e);
  }
  
}

export default getWeb3;